::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border: 1px solid #d5d5d5;
    padding-top: 5px;
    border-radius: 30px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 30px;
    background: #eeeeee;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #b0b0b0;
  }