h1, h2, h3, h4, h5, h6{
    margin-bottom: 10px;
    text-decoration: none;
    color: black;
    a{
        text-decoration: none;
        color: black;
    }
}

p, ul, ol{
    li{
      font-size: 1.15rem;
    }
}

h2{
    margin-bottom: 0px;
}

h3{
    font-size: 1.4rem;
}
h4{
    font-size: 1.3rem;
}
h5{
    font-size: 1.2rem;
}

p{
    font-size: 1.15rem;
}

//System stack for everything
body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }