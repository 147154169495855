// Colors for social icons
$github: #24292e;
$twitter: #1DA1F2;
$linkedin: #0e76a8;
$rss: #f26522;
$mail: #5d5c5d;
$instagram: #e1306c;
$facebook: #3b5998;
$messenger: #0084ff;
$youtube: #ff0000;
$whatsapp: #25d366;
$reddit: #ff4500;

header{
    margin-left: auto;
    margin-right: auto;
    width: 550px;
    overflow: hidden;
  
    #site_title{
        text-align: center;
        color: black;
        a{
            text-decoration: none;
        }
        h1{
            margin-bottom: 0px;
        }
        h2{
            margin-top: 0px;
            font-style: oblique;
            font-size: 1.2rem;
        }
    }
    #subheader{
        display: block;
        margin-top: 15px;
            .pages{
                display: block;
                float: left;
                a:hover{
                    color: black;
                }
                a{
                    text-decoration: none;
                    color: $base; 
                    margin-right: 3px;
                    font-size: 1.05rem;
                }
            }
            .social{
                display: block;
                float: right;
                i{
                    margin-right: 3px;
                    -o-transition: .5s;
                    -ms-transition: .5s;
                    -moz-transition: .5s;
                    -webkit-transition: .5s;
                    transition: .5s;
                }
                a{
                    color: $base;
                }
                i{
                    font-size: 1.1rem;
                }
                #github:hover{
                    color: $github;
                }
                #twitter:hover{
                    color: $twitter;
                }
                #linkedin:hover{
                    color: $linkedin;
                }
                #rss:hover{
                    color: $rss;
                }
                #envelope:hover{
                    color: $mail;
                }
                #instagram:hover{
                    color: $instagram;
                }
                #facebook:hover{
                    color: $facebook;
                }
                #messenger:hover{
                    color: $messenger;
                }
                #youtube:hover{
                    color: $youtube;
                }
                #whatsapp:hover{
                    color: $whatsapp;
                }
                #reddit:hover{
                    color: $reddit;
                }
            }
        }
}