.paginator{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;

    .older{
        text-align: left;
        -webkit-box-flex: 1;
    }

    .newer{
        text-align: right;
        -webkit-box-flex: 1;
    }

    a{
        text-decoration: none;
        color: $base;
    }

    a:hover{
        color: black;
    }

    .paginate-button{
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        padding: 15px 35px;
        display: inline-block;
        font-size: 16px;
    }
    .paginate-button:hover{
        background-color: $light-grey;
    }
}